import image from "./0.jpg";
import image1 from "./1.jpg";
import image2 from "./2.jpg";
import image3 from "./3.jpg";
import image4 from "./4.jpg";
import image5 from "./5.jpg";
import image6 from "./6.jpg";
import image7 from "./7.jpg";
import image8 from "./8.jpg";
import image9 from "./9.jpg";
import image10 from "./10.jpg";

export const activitiesImgs = [
	{
		src: image,
		thumbnail: image,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
		//customOverlay:  [image2]
		//thumbnailCaption: "raisrame"
	},
	{
		src: image1,
		thumbnail: image1,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
		//customOverlay:  [image2]
		//thumbnailCaption: "raisrame"
	},
	{
		src: image2,
		thumbnail: image2,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image3,
		thumbnail: image3,
		thumbnailWidth: 160,
		thumbnailHeight: 90,
		//isSelected: true,
		caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image4,
		thumbnail: image4,
		thumbnailWidth: 160,
		thumbnailHeight: 90,
		//isSelected: true,
		caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image5,
		thumbnail: image5,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image6,
		thumbnail: image6,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image7,
		thumbnail: image7,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image8,
		thumbnail: image8,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image9,
		thumbnail: image9,
		thumbnailWidth: 160,
		thumbnailHeight: 90
		//isSelected: true,
		//caption: "After Rain (Jeshu John - designerspics.com)"
	},
	{
		src: image10,
		thumbnail: image10,
		thumbnailWidth: 160,
		thumbnailHeight: 90,
		//isSelected: true,
		caption: "After Rain (Jeshu John - designerspics.com)"
	}
];
